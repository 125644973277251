export async function getInviteToken(token) {
  const { data } = await useAsyncData("token", () => $fetch(`/api/token/${token}`));
  return data;
}

export async function getProfile(token) {
  const { data } = await useAsyncData("profile", () => $fetch(`/api/token/${token}/profile`));
  return data;
}

export async function acceptInvite(token, displayName) {
  const { data } = await useAsyncData("accept-invite", () =>
    $fetch(`/api/accept-invite`, {
      method: "POST",
      body: JSON.stringify({ token, displayName }),
    }),
  );
  return data;
}

export async function getFirebaseAuth(token, firebaseUserId) {
  const { data } = await useAsyncData("auth", () =>
    $fetch(`/api/token/${token}/auth`, {
      method: "POST",
      body: JSON.stringify({ firebaseUserId }),
    }),
  );
  return data;
}
