import { getToken, getFirebaseIdToken } from "~/utils/auth";

export const useTokenStore = defineStore("token", {
  state: () => ({
    accountId: null,
    firebaseUserId: null,
    state: null,
    token: null,
    isValid: null,
    isLoading: true,
    isLoaded: false,
    isInitialized: false,
    idToken: null,
  }),
  getters: {
    isAssigned: (state) => state.state === "ASSIGNED",
    isFree: (state) => state.state === "FREE",
    isAccepted: (state) => state.state === "ACCEPTED",
    isRevoked: (state) => state.state === "REVOKED",
  },
  actions: {
    load(doc, tokenId) {
      Object.assign(this, { ...doc, token: tokenId });
      this.$loadComplete();
    },
    async init(tokenId) {
      this.isLoading = true;
      await getToken(tokenId)
        .then((token) => {
          this.$patch({ isValid: true, ...token });
        })
        .finally(() => (this.isLoading = false));
    },
    $loadComplete() {
      this.isLoaded = true;
      this.isLoading = false;
      this.isInitialized = true;
    },
    async fetchFirebaseIdToken() {
      if (!this.firebaseUserId) {
        throw new Error("firebaseUserId is not defined");
      }
      this.isLoading = true;
      await getFirebaseIdToken(this.token, this.firebaseUserId)
        .then((idToken) => {
          this.$patch({ idToken });
        })
        .finally(() => (this.isLoading = false));
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "token",
        storage: persistedState.localStorage,
      },
    ],
  },
});
