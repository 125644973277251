/* eslint-disable no-console */
import { doc, getDoc } from "firebase/firestore";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import {
  getFirebaseAuth,
  getInviteToken,
  acceptInvite,
  getProfile as getProf,
} from "~/utils/api/token";

export const getToken = async (tokenId) => {
  const _token = await getInviteToken(tokenId);
  const { token } = _token.value;
  return token;
};

export const verifyToken = async (tokenId) => {
  const _token = await getInviteToken(tokenId);
  const { isValid } = _token.value;
  return isValid;
};

export const getProfile = async (firebaseUserId) => {
  const { $db } = useNuxtApp();
  const docRef = doc($db, "profiles", firebaseUserId);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
};

export const createTokenStore = async (tokenId) => {
  const tokenStore = useTokenStore();
  const token = await getToken(tokenId);
  tokenStore.init(token, tokenId);
  return tokenStore;
};

export const createProfileStore = async (firebaseUserId) => {
  const profileStore = useProfileStore();
  const profile = await getProf(firebaseUserId);
  profileStore.setProfile(profile);
  return profileStore;
};

export const getFirebaseIdToken = async (tokenId, firebaseUserId) => {
  const authResponse = await getFirebaseAuth(tokenId, firebaseUserId);
  return authResponse.value.idToken;
};

export const tokenSignIn = async (firebaseUserId, tokenId) => {
  const authStore = useAuthStore();
  const auth = getAuth();
  const idToken = await getFirebaseIdToken(tokenId, firebaseUserId);

  await signInWithCustomToken(auth, idToken);
  authStore.setUser(auth.currentUser);
};

export const rebuildTokenStore = async (tokenId) => {
  const tokenStore = useTokenStore();
  tokenStore.$reset();
  const { token } = await getToken(tokenId);
  tokenStore.load(token);
  await tokenStore.init(tokenId);
};

export const rebuildProfileStore = async (tokenId) => {
  const profileStore = useProfileStore();
  profileStore.$reset();
  const profile = await getProf(tokenId);
  profileStore.load(profile.value);
};

export const completeRegistraton = async (name) => {
  const authStore = useAuthStore();
  const tokenStore = useTokenStore();
  const profileStore = useProfileStore();
  const certificateStore = useCertificateStore();

  console.log("1. tokenStore", tokenStore);
  const tokenId = tokenStore.token;
  console.log("2. tokenId", tokenId);
  const resp = await acceptInvite(tokenId, name);
  console.log("3. acceptInvite", tokenId, name, resp);
  console.log("4. tokenStore (original)", tokenStore.$state);
  await rebuildTokenStore(tokenId);
  console.log("4. tokenStore (up-to-date)", tokenStore.$state);
  console.log("5. profileStore (original)", profileStore.$state);
  await rebuildProfileStore(tokenId);
  console.log("5. profileStore (up-to-date)", profileStore.$state);
  await tokenStore.fetchFirebaseIdToken();
  console.log("6. tokenStore -> fetchFirebaseIdToken", tokenStore.$state);
  const auth = getAuth();
  await signInWithCustomToken(auth, tokenStore.idToken);
  authStore.setUser(auth.currentUser);
  console.log("7. authStore", authStore.$state);
  await certificateStore.fetchCertificate();
  console.log("8. certificateStore", certificateStore.$state);
};
