import { getProfile } from "~/utils/api/token";

export const useProfileStore = defineStore("profile", {
  state: () => ({
    accountId: null,
    email: null,
    profileId: null,
    firebaseUserId: null,
    isLoading: true,
    isLoaded: false,
    isInitialized: false,
  }),
  getters: {},
  actions: {
    load(profile) {
      Object.assign(this, profile);
      this.profileId = profile?.profileId || profile?.firebaseUserId;
      this.$loadComplete();
    },
    async init(tokenId) {
      this.isLoading = true;
      await getProfile(tokenId)
        .then((profile) => {
          this.$patch({ ...profile });
        })
        .finally(() => (this.isLoading = false));
    },
    $loadComplete() {
      this.isLoaded = true;
      this.isLoading = false;
      this.isInitialized = true;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "profile",
        storage: persistedState.localStorage,
      },
    ],
  },
});
