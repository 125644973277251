export const useCertificateStore = defineStore("certificate", {
  state: () => ({
    _isLoading: false,
    _certificate: undefined,
    _isCourseComplete: false,
  }),
  getters: {
    isLoading: (state) => state._isLoading,
    certificate: (state) => state._certificate,
    isCourseComplete: (state) => state._isCourseComplete,
  },
  actions: {
    async create(firebaseUserId, email) {
      const { data } = await useAsyncData("certificate", () =>
        $fetch(`/api/certificate`, {
          method: "POST",
          body: JSON.stringify({ firebaseUserId, email }),
        }),
      );
    },
  },
});
