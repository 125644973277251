export const useAuthStore = defineStore("auth", {
  state: () => ({
    currentUser: null,
    isAuthenticated: false,
    isLoading: true,
  }),
  getters: {
    getUser: (state) => state.currentUser,
  },
  actions: {
    setUser(currentUser) {
      this.currentUser = currentUser;

      if (currentUser) this.isAuthenticated = true;
      else this.isAuthenticated = false;

      this.isLoading = false;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "auth",
        storage: persistedState.localStorage,
      },
    ],
  },
});
